// import SocialMediaIcons from "../components/SocialMediaIcons";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {BsCodeSquare} from "react-icons/bs";
import uploadedFileLinkCV from '../downloads/CV2023_public.pdf';
import uploadedFileLinkCRM from '../downloads/CRM.pdf';

const Landing = ({ setSelectedPage }) => {
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");
    return (
        <section
            id="home"
            className="md:flex md:justify-between md:items-center gap-16 md:h-full py-10"
        >
            {/* image section */}
            <div className="basis-3/5 z-10 mt-16 md:mt-32 flex justify-center md:order-2">
                {isAboveLarge ? (
                    <div
                        className="relative z-0 ml-20 before:absolute before:-top-20 before:-left-20 before:rounded-t-[800px]
            before:w-full before:max-w-[400px] md:before:max-w-[600px] before:h-full before:border-2 before:border-blue before:z-[-1]"
                    >
                        {/*hover animation picture*/}
                        <img
                            alt="profile"
                            className="hover:filter hover:saturate-200 transition duration-500 z-10 w-full max-w-[400px] md:max-w-[600px]"
                            src="/assets/colin_contact-image.webp"
                        />
                    </div>
                ) : (
                    <img
                        alt="profile"
                        className="z-10 w-full max-w-[400px] md:max-w-[600px]"
                        src="/assets/colin_contact-image.webp"
                    />
                )}
            </div>

            {/* main text */}
            <div className="z-30 basis-2/5 mt-12 md:mt-32">
                {/* headings */}
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 },
                    }}
                >
                    {/*firstname & lastname */}
                    <p className="text-5xl font-playfair z-10 text-center md:text-start">
                        <p className="mb-7">Zimmermann {""} </p>

                        <span
                            className="xs:relative xs:text-deep-blue xs:font-semibold z-20 xs:before:content-brush
              before:absolute before:-left-[25px] before:-top-[70px] before:z-[-1]"
                        >
              Colin
            </span>

                    </p>

                    <p className="mt-10 mb-7 text-sm text-center md:text-start">

                        <BsCodeSquare className={"z-10 text-center md:text-start mb-5"} size={20} />
                        Dear customers<br></br><br></br> Welcome to a digital future oriented world. I'm an open and
                        motivated personality.
                        With my customer-oriented way of working, I turn your concerns into realitys. I attach particular
                        importance to reliable and good quality work.
                        Your project should be the centre of attention.<br></br><br></br>
                        When I'm not coding, I spend my time in nature, with friends or cooking something good. I have my heart in the right place and I'm a fan of the rock and metal scene.
                        On vacation I like to go on road trips and have a fascination for the Mustang classic car scene.
                    </p>
                </motion.div>

                {/* CALL TO ACTIONS */}
                <motion.div
                    className="flex mt-5 justify-center md:justify-center"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 },
                    }}
                >
                    <AnchorLink
                        className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-blue hover:text-white transition duration-500"
                        onClick={() => setSelectedPage("contact")}
                        href="#contact"
                    >
                        Contact me
                    </AnchorLink>
                    <a
                        className="rounded-r-sm bg-gradient-rainblue py-0.5 pr-0.5"
                        href={uploadedFileLinkCV}
                        download
                    >
                        <div className="bg-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center px-10 font-playfair">
                            Download my CV
                        </div>
                    </a>

                </motion.div>

                {/* Customer review button */}
                <motion.div
                    className="flex mt-5 justify-center md:justify-center"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 },
                    }}
                >

                    <a
                        className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-blue hover:text-white transition duration-500"
                        href={uploadedFileLinkCRM}
                        download
                    >
                        <div className="">
                            Customer reviews
                        </div>
                    </a>

                </motion.div>

                <motion.div
                    className="flex mt-5 justify-center md:justify-start"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 },
                    }}
                >
                    {/*<SocialMediaIcons />*/}
                </motion.div>
            </div>
        </section>
    );
};

export default Landing;