import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const MySkillsExtended = () => {
    const isAboveLarge = useMediaQuery("(min-width: 1060px)");
    return (
        <section id="skills" className="">
            {/* HEADER AND IMAGE SECTION */}

            {/* SKILLS */}
            <div className="md:flex md:justify-between gap-32">
                {/* EXPERIENCE */}
                <motion.div
                    className="md:w-1/3 mt-10"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className="relative h-32">
                        <div className="z-10">
                            <p className="font-playfair font-semibold text-5xl">Trainings</p>
                        </div>
                        <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
                    </div>
                    <p className="mt-5">
                        <li>MS Office 365</li>

                        <li>Courses for seniors</li>

                        <li>Calm, patient explanations</li>

                        <li>Understanding of ignorance </li>
                    </p>
                </motion.div>

                {/* INNOVATIVE */}
                <motion.div
                    className="md:w-1/3 mt-10"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className="relative h-32">
                        <div className="z-10">
                            <p className="font-playfair font-semibold text-5xl">Gamer PCs</p>
                        </div>
                        <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
                    </div>
                    <p className="mt-5">
                        <li>Assemble and configure gamer PCs</li>

                        <li>Overclocking</li>

                        <li>Pulse on the market </li>

                        <li>Professional cleaning</li>
                    </p>
                </motion.div>
                {/* IMAGINATIVE */}
                <motion.div
                    className="md:w-1/3 mt-10"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, y: 50 },
                        visible: { opacity: 1, y: 0 },
                    }}
                >
                    <div className="relative h-32">
                        <div className="z-10">
                            <p className="font-playfair font-semibold text-5xl">Sales</p>
                        </div>
                        <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
                    </div>
                    <p className="mt-5">
                        <li>Reconditioning of components </li>

                        <li>Electric waste disposal</li>

                        <li>Trade abroad with components</li>

                        <li>Electronics repairs </li>
                    </p>
                </motion.div>
                {/*<motion.div*/}
                {/*    className="md:w-1/3 mt-10"*/}
                {/*    initial="hidden"*/}
                {/*    whileInView="visible"*/}
                {/*    viewport={{ once: true, amount: 0.5 }}*/}
                {/*    transition={{ delay: 0.4, duration: 0.5 }}*/}
                {/*    variants={{*/}
                {/*        hidden: { opacity: 0, y: 50 },*/}
                {/*        visible: { opacity: 1, y: 0 },*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div className="relative h-32">*/}
                {/*        <div className="z-10">*/}
                {/*            <p className="font-playfair font-semibold text-5xl">Trainings</p>*/}
                {/*        </div>*/}
                {/*        <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />*/}
                {/*    </div>*/}
                {/*    <p className="mt-5">*/}
                {/*        <li>Clarification of questions in dealing with MS Office 365</li>*/}

                {/*        <li>Courses for seniors</li>*/}

                {/*        <li>Calm, patient explanations</li>*/}

                {/*        <li>Understanding of ignorance in the technical area </li>*/}
                {/*    </p>*/}
                {/*</motion.div>*/}
            </div>
        </section>
    );
};

export default MySkillsExtended;