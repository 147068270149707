import {AiFillGithub, AiFillInstagram} from "react-icons/ai";
import {MdHomeWork} from "react-icons/md";

const SocialMediaIcons = () => {
    return (
        <div className="flex justify-center mt-5 my-10 gap-7">
            {/*Instagram*/}
            <a
                className="hover:opacity-50 transition duration-500"
                href="https://github.com/Nightowl222c"
                target="_blank"
                rel="noreferrer"
            >
                <AiFillGithub size={30} />
            </a>
            {/*Github*/}
            <a
                className="hover:opacity-50 transition duration-500"
                href="https://www.instagram.com/nightowl222c/"
                target="_blank"
                rel="noreferrer"
            >
                <AiFillInstagram size={30} />
            </a>
            {/*Chirips IT-Support*/}
            <a
                className="hover:opacity-50 transition duration-500"
                href="https://www.chirips-it-support.ch"
                target="_blank"
                rel="noreferrer"
            >
                <MdHomeWork size={30} />
            </a>
        </div>
    );
};

export default SocialMediaIcons;