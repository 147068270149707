import SocialMediaIcons from "../components/SocialMediaIcons";
import {BsFillMapFill} from "react-icons/bs";

const Footer = () => {
    return (
        <footer className="h-60 bg-grey pt-10">
            <div className="w-10/12 mx-auto">
                <SocialMediaIcons />
                <div className="md:flex justify-center md:justify-between text-center pb-5">
                    <div>
                        <p className=" footer-title font-playfair text-md font-bold mb-5">Colin Zimmermann</p>
                        <a href="tel:+41772214200"><p className="font-playfair text-md text-black" style={{cursor:  'pointer' }}>+41 77 221 42 00</p></a>
                        <p className="font-playfair text-md text-black mb-5">
                            <a href="mailto:colinzimmermann93@gmail.com"
                               className="font-playfair"
                            >
                                colinzimmermann93@gmail.com
                            </a>
                        </p>
                    </div>
                    <div>
                        <p className="font-playfair text-md text-black">Bogenackerstrasse 24</p>
                        <p className="font-playfair text-md text-black mb-5">8632 Tann ZH</p>
                        <a href="https://www.google.com/maps?q=Bogenackerstrasse+24,+8632+Tann"
                           target="_blank" rel="noopener noreferrer"
                           className="map-location mb-5 font-playfair text-md text-black hover:underline cursor-pointer">
                            <BsFillMapFill />
                        </a>
                    </div>
                    <div>
                        <p className="font-playfair text-md text-black mb-5">
                            © 2023 by Colin Zimmermann
                        </p>
                        <br></br>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;